import React from "react";
import Container from "../../components/chat-channel/Container";
import Layout from "../../components/chat-channel/Layout";
import RightImageWithContentFeature from "../../components/chat-channel/RightImageWithContent";
import LeftImageWithContent from "../../components/chat-channel/LeftImageWithContent";
import ArrowRightICon from "../../components/common/Icons";
import { CardsThree } from "../homepage";
import FooterForm from "../../components/common/FooterForm";
import TitleAndMetaTags from "../../components/common/TitleAndHeader";
import TopFormWithImage from "../../components/TopFormCenterImage";
import { RequestForm } from "../../components/form";
import { OnScrollPopup } from "../conversational-ai-platform/hr-chatbot";
import { useMedia } from "use-media";
import { GoToPopup } from "../homepage";
import { CookiesPoup } from "../homepage";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";

const TopImage = require("../../assets/img/feature/knowledge_ai/knowledgeai_header.png");
const TopImage_mob = require("../../assets/img/feature/knowledge_ai/knowledgeai_header.png");

export const icon1 = require("../../assets/img/feature/sec_1_bg.png");
export const icon2 = require("../../assets/img/feature/sec_2_bg.png");
export const icon3 = require("../../assets/img/feature/sec_3_bg.png");
export const icon4 = require("../../assets/img/feature/sec_4_bg.png");

export const cardOne = require("../../assets/images/homepage/group1.png");
export const cardTwo = require("../../assets/images/homepage/group4.png");

const section1 = require("../../assets/img/feature/knowledge_ai/knowledgeai_sec_1.png");
const section2 = require("../../assets/img/feature/knowledge_ai/knowledgeai_sec_2.png");
const section3 = require("../../assets/img/feature/knowledge_ai/knowledgeai_sec_3.png");
const section4 = require("../../assets/img/feature/knowledge_ai/knowledgeai_sec_4.png");
const section5 = require("../../assets/img/feature/knowledge_ai/knowledgeai_sec_5.png");

const related1 = require("../../assets/img/feature/knowledge_ai/related_artical_1.png");
const related2 = require("../../assets/img/feature/knowledge_ai/related_artical_2.png");
const related3 = require("../../assets/img/feature/knowledge_ai/related_artical_3.png");

const one = require("../../assets/img/feature/knowledge_ai/1.svg");
const two = require("../../assets/img/feature/knowledge_ai/2.svg");
const three = require("../../assets/img/feature/knowledge_ai/3.svg");
const four = require("../../assets/img/feature/knowledge_ai/4.svg");
const five = require("../../assets/img/feature/knowledge_ai/5.svg");

export const autoDesigner = require("../../assets/img/feature/updated/automation.png");
export const chatChannel = require("../../assets/img/feature/updated/chat-channel.png");
export const logs = require("../../assets/img/feature/updated/logs.png");
export const marketplace = require("../../assets/img/feature/updated/marketplace.png");
export const dialog = require("../../assets/img/feature/updated/dialog.png");
export const onprem_icon = require("../../assets/img/feature/updated/on_prem.png");
export const faqBuilder = require("../../assets/img/feature/agent_handover/all_feature_faq_icon.png");
export const liveAgent = require("../../assets/img/feature/agent_handover/all_feature_agent_handover_icon.png");
export const chabotApproval = require("../../assets/img/feature/agent_handover/all_feature_approvals_icon.png");
const knowledgeAi = require("../../assets/img/feature/knowledge_ai/knowledgeai_icon.svg");

export const c_ai = require("../../assets/img/feature/updated/c_ai.png");

export const cardData = [
  {
    image: cardOne,
    header: "Workativ for Conversational IT Support",
    content: `Deliver faster and superior IT Support, powered by Conversational AI and Automation. Augment your IT Help Desk to scale your support easily in a few clicks. `,
    content_1: `No coding required.`,
    linkAddress: "/assistant/it-helpdesk-chatbot",
    backgroundClass: "bg_card_1",
  },
  {
    image: cardTwo,
    header: "Workativ for Conversational HR Support",
    content: `Transform HR experience for employees with our AI-powered chatbots with prebuilt HR process automation. Free up your HR staff to focus on high value work.`,
    content_1: ` No coding required.`,
    linkAddress: "/conversational-ai-platform/conversational-hr-support",
    backgroundClass: "bg_card_2",
  },
];
export const faqData = [
  {
    header: "What is Knowledge AI, and how do I use it? ",
    paragraph:
      "Knowledge AI enables you to provide contextual and accurate responses to your employee's queries. You can connect Knowledge AI with your preferred knowledge source (i.e., SharePoint, Website, etc.) to retrieve answers.",
    active: "active",
    uuid: "a",
  },
  {
    header: "How is Knowledge AI different from ChatGPT? ",
    paragraph:
      "ChatGPT is a pre-trained question-answer bot. You can best use it to answer general questions and continue with follow-up questions. But, you cannot use ChatGPT to answer employees’ queries and solve workplace problems.",
    paragraph1:
      "Knowledge AI applies the power of LLM or Generative AI to fetch answers to your employee queries from your preferred knowledge resources to provide accurate responses.",
  },
  {
    header: "What are the use cases of Knowledge AI?",
    paragraph:
      "KnowledgeAI can be used to provide instant responses to repetitive employee queries, such as",
    listItems: [
      "1. Printer issues",
      "2. Internet issue",
      "3. Application issue",
      "4. Asset request",
      "5. Employee onboarding",
      "6. PTO inquiry",
    ],
    paragraph1: "And much more!",
  },
  {
    header: "How do I connect my knowledge articles to Knowledge AI? 							",
    paragraph:
      "Workativ platform makes it easy to use Knowledge AI by connecting your knowledge articles with simple steps without coding. You can use the Workativ platform to connect your Knowledge base (i.e., SharePoint, Website, etc.), enabling Knowledge AI to instantly access and utilize this information to deliver swift and precise answers to employee queries efficiently.",
  },
  {
    header: "How does Knowledge AI work?",
    paragraph:
      "Knowledge AI uses LLMs and Generative AI to analyze and comprehend text-based information. It then generates responses tailored to the specific questions or queries posed by users.",
  },
  {
    header: "How accurate is Knowledge AI in providing information?",
    paragraph:
      "The accuracy of Knowledge AI depends on the quality of the underlying knowledge base. It can be highly accurate when properly configured and trained on reliable sources of information.",
  },
  {
    header: "Can Knowledge AI learn and improve over time?		",
    paragraph:
      "Knowledge AI can learn and improve its responses over time through continuous training on past interactions and feedback. This helps it stay up-to-date and provide better answers to user queries.",
  },
  {
    header: "Is Knowledge AI suitable for business and enterprise use?",

    paragraph:
      "Knowledge AI has great potential in businesses and enterprises to improve employee support, automate repetitive tasks, and enhance productivity.",
  },
  {
    header:
      "Can Knowledge AI understand user intent and context in conversations?",

    paragraph:
      "Knowledge AI is designed to understand user intent and context in conversations, allowing it to provide relevant responses that consider the conversation's flow.",
  },
  {
    header: "Will there be hallucinations in the chatbot answers? ",

    paragraph:
      "Hallucinations happen if you use inaccurate content or misinformation to train the Knowledge AI. To control hallucinations, use knowledge sources free from ambiguity or inaccuracies.",
  },
  {
    header: "Will my data be exposed to the public domain?",

    paragraph:
      "Workativ has implemented robust security processes and controls that comply with industry-leading standards and regulations. Workativ ensures our platform's data, operational, and physical security with highly secure and reliable services.",
  },
  {
    header: "Will my knowledge articles be used to train generic model?",

    paragraph:
      "No, your knowledge resource will not be used to train the generic model. The data or articles that Knowledge AI uses are specifically used for your workspace. I.e. used to generate answers for your users only. ",
  },
];
export const otherFeatures = [
  {
    image: c_ai,
    alt: "Conversational AI Platform",
    header: "Conversational AI Platform",
    content:
      "Learn more on workativ assistants’ conversational ai platform capabilities.",
    link: "/conversational-ai-platform/conversational-ai",
  },
  {
    image: dialog,
    alt: "Chatbot Builder​",
    header: "Chatbot Builder​",
    content:
      "Use no-code visual canvas to create engaging conversations for your chatbot easily in minutes.",
    link: "/conversational-ai-platform/chatbot-builder",
  },
  {
    image: faqBuilder,
    alt: "FAQ Bot Builder",
    header: "FAQ Bot Builder",
    content:
      "Simple and easy to understand UI to help you automate your repetitive FAQs easily in minutes.",
    link: "/conversational-ai-platform/faq-chatbot-builder",
  },
  {
    image: autoDesigner,
    alt: "Chatbot Automation​",
    header: "Chatbot Automation​",
    content:
      "Add IT or HR workflow automations to chatbot through app integration and pre-built app workflows in few clicks.",
    link: "/conversational-ai-platform/chatbot-automation",
  },
  {
    image: chabotApproval,
    alt: "Chatbot Approvals",
    header: "Chatbot Approvals​",
    content:
      "Approving chatbot tasks made easy with our approval management. Set approval reminders, expiry, tracking, and more.",
    link: "/conversational-ai-platform/approval-management-chatbot",
  },
  {
    image: marketplace,
    alt: "Chatbot Workflow Marketplace",
    header: "Chatbot Marketplace",
    content:
      "Download pre-built chatbots, 100+ app integrations, and 1000+ app workflows in seconds and go live instantly.",
    link: "/conversational-ai-platform/app-workflow-template-marketplace",
  },
  {
    image: chatChannel,
    alt: "Omnichannel",
    header: "Omnichannel",
    content:
      "Deploy chatbot on your favorite collaboration apps like Teams, Slack, or as Chat Widget easily in few clicks.",
    link: "/conversational-ai-platform/chatbot-channels",
  },

  {
    image: liveAgent,
    alt: "Live Agent Handover",
    header: "Live Agent Handover",
    content:
      "Seamless ‘agent handover’ of chatbot to live agents with complete user context and conversation history.",
    link: "/conversational-ai-platform/agent-handover-chatbot",
  },
  {
    image: logs,
    alt: "Chatbot Analytics & Performance",
    header: "Chatbot Analytics & Performance",
    content:
      "Gather insight to craft better end user experiences and deliver better chatbot support to your employees.",
    link: "/conversational-ai-platform/chatbot-analytics-performance",
  },
];

export default function DialogDesigner() {
  const isSmall = useMedia({ maxWidth: "520px" });
  return (
    <>
      <TitleAndMetaTags
        title="Transform your workforce efficiency with Knowledge AI"
        description="Harness the power of Knowledge AI to provide instant solution to your employee queries and supercharge productivity."
        keywords={["IT Helpdesk Chatbot", "HR Chatbot", "Service Desk Chatbot"]}
        ogImage={TopImage}
        ogTitle="Transform your workforce efficiency with Knowledge AI"
        ogDescription="Harness the power of Knowledge AI to provide instant solution to your employee queries and supercharge productivity."
      />
      <Container
        additionalClass={"feature-display-none"}
        additionalClassParent={"indvidual_features_parent_cont"}
      >
        <Layout backgroundColor={"bg_feature"} logoFor="ASSISTANT">
          <TopFormWithImage
            image={TopImage}
            altImage={"Conversation AI Platform"}
            image_mob={TopImage_mob}
            additionalClassSection={"features_indvidual_dec"}
            additionalClassContent={"knowledge-ai-content"}
            additionalClassImage={"width-100"}
          >
            <TopFormWithImage.Header reacentrelease={true}>
              Knowledge AI
            </TopFormWithImage.Header>
            <TopFormWithImage.Content>
              Create ChatGPT for your knowledge base in seconds.
              <br /> Our knowledge AI leverages the power of Large Language
              Model (LLM) and Generative AI
              <br /> turning your knowledge into generative AI chats for user
              queries.
            </TopFormWithImage.Content>
            <RequestForm isFooterForm={false} />
          </TopFormWithImage>
          {/* {isSmall ? null : <OnScrollPopup />} */}
          <section className="features_content_left features_img_left pl-10-tage landing_page trial_page_img bg_trial_page background-white p-0">
            <div className="container">
              <div className="row">
                <div className="col-md-6 feature_page_content_left">
                  <img src={one} className="content-num-img"></img>
                  <h3 className="font-section-header">
                    <span>Choose your knowledge source</span>
                  </h3>
                  <p className="font-section-normal-text-testimonials line-height-18">
                    Connect Knowledge AI with your preferred knowledge sources
                    (i.e., SharePoint, Website, etc.) to generate accurate and
                    comprehensive answers to your employee queries.
                  </p>
                </div>
                <div className="col-md-6 feature_page_img_right ">
                  <img
                    loading="lazy"
                    src={section1}
                    alt="Choose your knowledge source"
                  />
                </div>
              </div>
            </div>
          </section>
          <section className="features_content_left features_img_left pl-10-tage landing_page trial_page_img bg_trial_page background-grey">
            <div className="container">
              <div className="row">
                <div className="col-md-6 feature_page_content_left">
                  <img src={two} className="content-num-img"></img>
                  <h3 className="font-section-header">
                    <span>Customize your workflows</span>
                  </h3>
                  <p className="font-section-normal-text-testimonials line-height-18">
                    Facilitate workflow automation for handling a wide array of
                    employee queries effortlessly by combining Knowledge AI with
                    conversational dialog flows, ensuring instant solutions to
                    simple and complex issues.
                  </p>
                </div>
                <div className="col-md-6 feature_page_img_right ">
                  <img
                    loading="lazy"
                    src={section2}
                    alt="Customize your workflows"
                  />
                </div>
              </div>
            </div>
          </section>
          <section className="features_content_left features_img_left pl-10-tage landing_page trial_page_img bg_trial_page background-white p-0">
            <div className="container">
              <div className="row">
                <div className="col-md-6 feature_page_content_left">
                  <img src={three} className="content-num-img"></img>
                  <h3 className="font-section-header">
                    <span>Add human touch</span>
                  </h3>
                  <p className="font-section-normal-text-testimonials line-height-18">
                    With live agent handover capability, the chatbot can hand
                    over conversations to live agents. keep track of the
                    conversations, making it simple for your agents to take over
                    and assist users without delays.
                  </p>
                </div>
                <div className="col-md-6 feature_page_img_right ">
                  <img loading="lazy" src={section3} alt="Add human touch" />
                </div>
              </div>
            </div>
          </section>
          <section className="features_content_left features_img_left pl-10-tage landing_page trial_page_img bg_trial_page background-grey">
            <div className="container">
              <div className="row">
                <div className="col-md-6 feature_page_content_left">
                  <img src={four} className="content-num-img"></img>
                  <h3 className="font-section-header">
                    <span>Deploy the bot</span>
                  </h3>
                  <p className="font-section-normal-text-testimonials line-height-18">
                    Why not make it truly yours? Tailor your bot’s appearance
                    and functionality to match your brand’s essence. From
                    configuring branding and logo to selecting colors that
                    resonate with your brand, you can create a warm and
                    emotionally connected experience for every user. Then you
                    can deploy the bot in your preferred channel (i.e., MS
                    Teams, Slack, or Chat widget)
                  </p>
                </div>
                <div className="col-md-6 feature_page_img_right ">
                  <img loading="lazy" src={section4} alt="Deploy the bot" />
                </div>
              </div>
            </div>
          </section>
          <section className="features_content_left features_img_left pl-10-tage landing_page trial_page_img bg_trial_page background-white p-0">
            <div className="container">
              <div className="row">
                <div className="col-md-6 feature_page_content_left">
                  <img src={five} className="content-num-img"></img>
                  <h3 className="font-section-header">
                    <span>Measure and tweak</span>
                  </h3>
                  <p className="font-section-normal-text-testimonials line-height-18">
                    Maximize the use of our Knowledge AI bot for fast
                    resolutions of workplace issues more autonomously using
                    advanced AI-driven data and analytics. Take control of the
                    bot analytics dashboard that shows the number of tickets
                    raised, the number of queries answered using KnowledgeAI,
                    and track the rate of unresolved queries – all in one place.
                  </p>
                </div>
                <div className="col-md-6 feature_page_img_right ">
                  <img loading="lazy" src={section5} alt="Measure and tweak" />
                </div>
              </div>
            </div>
          </section>

          <Faq />
          <RelatedArticles />
          <div className="isfooter_form">
            <RequestForm isFooterForm={true} />
          </div>
          <section className="cards_features background-grey mb-60">
            <div className="container">
              <div className="row">
                <div className="col-md-12 col-lg-12 col-12 col-sm-12 cards_features_header">
                  <div className="build_needs">
                    <div className="col-lg-12 col-md-12 col-12 p-0 center_features_header">
                      <h2 className="font-section-header">
                        Explore More Features
                      </h2>
                      <p className="font-section-normal-text-testimonials line-height-18">
                        Our 3-in-1 support automation platform combines the
                        power of Gen AI chatbot for automating chats, App
                        workflow automation for auto-resolution of issues, and
                        Shared Live Inbox for seamless live chat interactions.
                        And more…!
                      </p>
                    </div>
                  </div>
                  <section className="whitepaper_cards">
                    <div className="container-fluid p-0 c_a">
                      <div className="row">
                        <ul className="cards">
                          {otherFeatures.map((data) => (
                            <li className="cards__item">
                              <div className="card">
                                <div className="card__image">
                                  <img
                                    loading="lazy"
                                    src={data.image}
                                    alt={data.alt}
                                  ></img>
                                </div>
                                <div className="card__content">
                                  <div className="card__title font-section-normal-text-medium">
                                    {data.header}
                                    {data.new ? (
                                      <span className="all-features-new-launch font-section-small-signup-form">
                                        New
                                      </span>
                                    ) : null}
                                  </div>
                                  <p className="card__text font-section-small-text pb-0">
                                    {data.content}
                                  </p>
                                  <div class="card_link_landing">
                                    <a
                                      class="font-section-text-link text-decoration-underline text-underline-offset color-anchor-blue"
                                      href={data.link}
                                    >
                                      Know more&nbsp; &#8594;
                                    </a>
                                  </div>
                                </div>
                              </div>
                            </li>
                          ))}
                        </ul>
                      </div>
                    </div>
                  </section>
                </div>
              </div>
            </div>
          </section>
        </Layout>
      </Container>
    </>
  );
}

function Faq() {
  return (
    <React.Fragment>
      <div className="mob_accordian mob_accordian_faq background-grey p-40">
        <section className="accordian_landing trial_accordian_landing">
          <div className="container">
            <h2 className="font-section-sub-header">
              Frequently Asked Questions
            </h2>
            <div className="col-12 pl-0 faq_landing">
              <Accordion
                id="accordion"
                className="accordion pl-0"
                preExpanded={["a"]}
              >
                <div className="accordion-wrapper pricing_faq">
                  {faqData.map((data) => (
                    <AccordionItem uuid={data.uuid}>
                      <AccordionItemHeading>
                        <AccordionItemButton>{data.header}</AccordionItemButton>
                      </AccordionItemHeading>
                      <AccordionItemPanel>
                        <p
                          dangerouslySetInnerHTML={{ __html: data.paragraph }}
                          className="font-section-normal-text-testimonials line-height-18"
                        />
                        {data.listItems ? (
                          <ol className="font-section-normal-text-testimonials">
                            {data.listItems.map((item, index) => (
                              <li key={index}>{item}</li>
                            ))}
                          </ol>
                        ) : null}
                        {data.paragraph1 && (
                          <p
                            className="para_2_pricing font-section-normal-text-testimonials line-height-18"
                            dangerouslySetInnerHTML={{
                              __html: data.paragraph1,
                            }}
                          />
                        )}
                      </AccordionItemPanel>
                    </AccordionItem>
                  ))}
                </div>
              </Accordion>
            </div>
          </div>
        </section>
      </div>
    </React.Fragment>
  );
}

const relatedArticles = [
  {
    image: related1,
    headerContent:
      "How to Use Generative AI to Automate Knowledge Management Tasks",
    link: `https://workativ.com/conversational-ai-platform/blog/generative-ai-knowledge-management-automation`,
  },
  {
    image: related2,
    headerContent: "How to Implement Generative AI with Knowledge base",
    link: `https://workativ.com/conversational-ai-platform/blog/implement-generative-ai-knowledge-base`,
  },
  {
    image: related3,
    headerContent: "How Generative AI Can Help Reduce Resolution Time",
    link: `https://workativ.com/conversational-ai-platform/blog/generativei-ai-reduce-resolution-time`,
  },
];
function RelatedArticles() {
  const isSmall = useMedia({ maxWidth: "520px" });
  return (
    <section className="w-100 float-left related-article-section">
      <div className="container">
        <h3 className="font-section-sub-header text-align-center mb-4">
          Related Articles
        </h3>
        <div className="related-articles-flex-wrapper">
          {relatedArticles.map((data) => (
            <div className="related-articles-flex-box">
              <img src={data.image} />

              <div class="related-articles-card-bottom">
                <h2
                  class={`font-section-normal-text-testimonials-medium line-height-18  ${
                    isSmall ? "height-5-rem-mob" : "height-7-rem"
                  }`}
                >
                  {data.headerContent}
                </h2>
                <a
                  href={data.link}
                  className="font-section-normal-text-testimonials-medium"
                >
                  Read Blog &#8594;
                </a>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}
